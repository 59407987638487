Wethod.module('HeaderApp.Header', function (Header, Wethod, Backbone, Marionette, $) {
  /**
   * Expects an object with:
   * - fragment: URL fragment to use for calls
   */
  this.BookmarkModel = Backbone.Model.extend({
    urlRoot: `${APIURL}/desk/favorite/`,
    getFavorite() {
      const defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: this.urlRoot + this.get('fragment'),
        xhrFields: {
          withCredentials: true,
        },
      }).done((response) => {
        if (response.code !== 200) {
          defer.reject();
        } else {
          defer.resolve(response.data);
        }
      }).fail((response) => {
        defer.reject(response);
      });

      return defer.promise();
    },
    saveFavorite() {
      const defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: this.urlRoot + this.get('fragment'),
        xhrFields: {
          withCredentials: true,
        },
      }).done((response) => {
        if (response.code !== 200) {
          defer.reject();
        } else {
          defer.resolve(response.data);
        }
      }).fail((response) => {
        defer.reject(response);
      });

      return defer.promise();
    },
    removeFavorite() {
      const defer = $.Deferred();

      $.ajax({
        method: 'DELETE',
        crossDomain: true,
        url: this.urlRoot + this.get('favorite').id,
        xhrFields: {
          withCredentials: true,
        },
      }).done((response) => {
        if (response.code !== 200) {
          defer.reject();
        } else {
          defer.resolve(response);
        }
      }).fail((response) => {
        defer.reject(response);
      });

      return defer.promise();
    },
  });

  module.exports = this.BookmarkModel;
});
