const templateHtml = require('../templates/BaseModalTemplate.html');

Wethod.module('Modal', (Modal, Wethod, Backbone, Marionette, $, _) => {
  const modalW = 350;
  const modalH = 200;

  window.BaseModalView = Marionette.LayoutView.extend({
    template: _.template(templateHtml),
    className: 'modal-wrapper',
    regions: {
      titleRegion: "[data-region='title']",
      bodyRegion: "[data-region='body']",
      actionRegion: "[data-region='actions']",
      feedbackRegion: "[data-region='feedback']",
    },
    /**
         * Places the modal vertically & horizontally center aligned.
         */
    placeModal() {
      const contextW = $(window).width();
      const contextH = $(window).height();
      const posLeft = (contextW - modalW) / 2;
      const posTop = (contextH - modalH) / 2;

      this.$el.css({ left: posLeft, top: posTop }).show();
    },
    /**
         * Closes the modal.
         */
    close() {
      this.trigger('modal:close');
      this.destroy();
    },
  });

  module.exports = window.BaseModalView;
});
