const templateHtml = require('../templates/HowToTourTemplate.html');

Wethod.module('Header.Section', function (HeaderSection, Wethod, Backbone, Marionette, $, _) {
  this.HowToTourView = Marionette.ItemView.extend({
    className: 'helper-modal__item helper-modal__how-to-link',
    tagName: 'li',
    template: _.template(templateHtml),
    events: {
      click: 'onClick',
    },
    onClick(e) {
      if (this.model.get('show_owned_companies')) {
        e.preventDefault();
        this.trigger('helper:tour:owner-only:clicked');
      }
    },
  });

  module.exports = this.HowToTourView;
});
