/* eslint-disable no-shadow,backbone/event-scope */
Wethod.module('HeaderApp.Header', function (Header, Wethod, Backbone) {
  /**
   * Expects an object with:
   * - helper_url: the url fragment to reach a specific guide's section
   * - tour_id: Intercom id
   * - tour_start_page: in which page the tour should start
   *
   * After init this model you can start a tour with dispatcher.trigger('tour:start', tourId).
   *
   * Owners only tours are displayed in the owner's company, all the other tours are displayed in
   * playground.
   */
  this.HelperModalModel = Backbone.Model.extend({
    initialize() {
      const currentLocation = Wethod.userInfo.get('company');
      this.set('is_owner', this.getOwnedCompanies().length > 0);
      this.set('support_url', this.getSupportUrl());
      this.set('playground_url', currentLocation === 'playground' ? null : this.getPlaygroundUrl());
      this.set('tour_url', this.getTourUrl(this.get('tour_id'), this.get('tour_start_page')));
      this.set('how_to_tours', this.getHowToCollection());

      dispatcher.on('tour:start', this.startTour.bind(this));
    },
    _supportDomain: 'http://support.wethod.com/',
    // Available Intercom tours
    _howToTours: {
      user: [{ // tours for simple user
        id: 286053,
        title: 'Track your sales opportunities',
        tour_start_page: 'pipeline/opportunities',
      }, {
        id: 286036,
        title: 'Create your first project',
        tour_start_page: 'pipeline/projects',
      }, {
        id: 51691,
        title: 'Plan your team',
        tour_start_page: 'planning/people',
      }, {
        id: 74323,
        title: 'Timesheet, Project Status and Budget consumption',
        tour_start_page: 'timesheet',
      }, {
        id: 31036,
        title: 'Check a project report',
        tour_start_page: 'project/29/report',
      }],
      owner: [{ // tours for company owner
        id: 286039,
        title: 'Track your sales opportunities',
        tour_start_page: 'pipeline/opportunities',
      }, {
        id: 258288,
        title: 'Create your first project',
        tour_start_page: 'pipeline/projects',
      }, {
        id: 51691,
        title: 'Plan your team',
        tour_start_page: 'planning/people',
      }, {
        id: 30484,
        title: 'Timesheet, Project Status and Budget consumption',
        tour_start_page: 'timesheet',
      }, {
        id: 31036,
        title: 'Check a project report',
        tour_start_page: 'project/29/report',
      }, {
        id: 63658,
        title: 'Set up your company',
        tour_start_page: 'settings/company',
        // Does the tour have to start in playground or in one of the user's owned company?
        owner_only: true,
      }, {
        id: 63714,
        title: 'Manage your team and set permissions',
        tour_start_page: 'settings/team',
        owner_only: true,
      }, {
        id: 74397,
        title: 'Invite new teammates',
        tour_start_page: 'settings/team',
        owner_only: true,
      }],
    },
    /**
     * Returns all the companies owned by the user (playground excluded because it's a "fake").
     */
    getOwnedCompanies() {
      const companies = Wethod.userInfo.get('companies_available');

      return companies.filter((company) => company.is_owner === true && company.name !== 'playground');
    },
    /**
     * Returns the tours linked to each company owned by the user.
     * @param tourId
     * @return {Backbone.Collection}
     */
    getTourForOwnedCompanies(tourId) {
      const tour = this._howToTours.owner.filter((tour) => tour.id === tourId)[0];
      const tours = this.getOwnedCompanies().map((company) => ({
        title: company.name,
        tour_url: this.getTourUrl(tour.id, tour.tour_start_page, company.url),
      }));

      return new Backbone.Collection(tours);
    },
    /**
     * Returns the URL to reach the guide section specified by 'support_url'.
     *
     * Example: if support_url = 'pipeline/index/#projects', this function returns
     * 'http://support.wethod.com/pipeline/index/#projects'.
     *
     * @returns {string|null}
     */
    getSupportUrl() {
      if (this.get('helper_url')) {
        return this._supportDomain + this.get('helper_url');
      }
      return null;
    },
    /**
     * Returns the playground url.
     * @return {*}
     */
    getPlaygroundUrl() {
      const userCompanies = Wethod.userInfo.get('companies_available');
      const namedPlayground = userCompanies.filter((company) => company.name === 'playground');
      if (namedPlayground.length > 0) {
        const { protocol } = window.location;
        return `${protocol}//${namedPlayground[0].url}`;
      }
      return null;
    },
    /**
     * Returns true if the given tour's id belongs to a tour visible to company owners only.
     * @param id
     * @return {boolean|*}
     */
    isOwnerOnly(id) {
      return this._howToTours.owner.filter((tour) => tour.id === id)[0].owner_only;
    },
    /**
     * Returns the url to start the tour with the given id.
     * @param id
     * @param startPage
     * @param company in which company you want the tour to start
     * @return {*}
     */
    getTourUrl(id, startPage, company) {
      const playgroundUrl = this.getPlaygroundUrl();
      if (Number.isInteger(id) && startPage !== undefined && playgroundUrl !== null) {
        const fragment = `/#${startPage}?product_tour_id=${id}`;
        if (company !== undefined) {
          const { protocol } = window.location;
          return `${protocol}//${company}${fragment}`;
        }
        return playgroundUrl + fragment;
      }
      return null;
    },
    /**
     * Returns a collection of how-tos ready to be displayed. How tos dedicated to owners are
     * treated like so:
     * - if user owns 0 companies: they're not displayed
     * - if user owns > 1 companies: a list of of companies is shown, user can decide where to
     * continue the tour
     * @return {Backbone.Collection}
     */
    getHowToCollection() {
      const ownedCompanies = this.getOwnedCompanies();
      const tours = this.get('is_owner') ? this._howToTours.owner : this._howToTours.user;

      const collection = tours.map((tour, index) => {
        const tourOptions = {
          id: tour.id,
          title: `${index + 1}. ${tour.title}`,
          tour_url: this.getTourUrl(tour.id, tour.tour_start_page),
          owner_only: tour.owner_only,
          show_owned_companies: false,
        };

        if (tour.owner_only) {
          if (ownedCompanies.length === 1) {
            const company = ownedCompanies[0];
            tourOptions.tour_url = this.getTourUrl(tour.id, tour.tour_start_page, company.url);
          }

          if (ownedCompanies.length > 1) {
            tourOptions.show_owned_companies = true;
          }
        }

        return tourOptions;
      });

      return new Backbone.Collection(collection);
    },
    /**
     * Returns true if the tour with the given id is in _howToTours and its tour_start_page is the
     * current one.
     */
    tourStartsHere(id) {
      const currentFragment = Backbone.history.fragment.replace(/\?.*/, '');
      const howToTours = this.get('is_owner') ? this._howToTours.owner : this._howToTours.user;
      const tours = howToTours
        .filter((tour) => tour.tour_start_page === currentFragment && tour.id === id);
      if (tours.length === 1) {
        const currentCompany = Wethod.userInfo.get('company');
        return tours[0].owner_only ? currentCompany !== 'playground' : currentCompany === 'playground';
      }
      return false;
    },
    canStartTour(id) {
      const isPageTour = parseInt(id) === this.get('tour_id');

      return isPageTour || this.tourStartsHere(id);
    },
    startTour(tourId) {
      if (tourId !== undefined) {
        const id = parseInt(tourId);
        if (this.canStartTour(id) && Intercom) {
          Intercom('startTour', id);
        }
      }
    },
  });

  module.exports = this.HelperModalModel;
});
