const BaseModalView = require('../../../modal/views/BaseModalView.js');
const templateHtml = require('../templates/HelperModalTemplate.html');
const HowToToursView = require('./HowToToursView');

Wethod.module('Header.Section', function (HeaderSection, Wethod, Backbone, Marionette, $, _) {
  this.HelperModalView = BaseModalView.extend({
    className: 'helper-modal__wrapper',
    template: _.template(templateHtml),
    ui: {
      closeButton: '[data-action="close"]',
      backButton: '[data-action="backButton"]',
      tabs: '[data-action="tabs"]',
      container: '.helper-modal__container',
      body: '[data-region=\'body\']',
      header: '[data-region=\'header\']',
    },
    regions: {
      tours: '[data-region=\'tours\']',
      companies: '[data-region=\'companies\']',
    },
    events: {
      'click @ui.closeButton': 'onClickedCloseButton',
      'click @ui.backButton': 'onClickedBackButton',
    },
    childEvents: {
      'helper:tour:owner-only:clicked': 'onOwnerTourClicked',
    },
    onClickedBackButton() {
      this.ui.tabs.removeClass('helper-modal__tabs--second-visible');
    },
    /**
     * How tos dedicated to owners are treated like so:
     * - if user owns 0 companies: they're not displayed
     * - if user owns > 1 companies: a list of of companies is shown, user can decide where to
     * continue the tour
     *
     * @param childView
     * @param tourModel
     */
    onOwnerTourClicked(childView, tourModel) {
      const tourId = tourModel.get('id');
      const companyRelatedTours = this.model.getTourForOwnedCompanies(tourId);
      const companiesView = new HowToToursView({ collection: companyRelatedTours });
      this.companies.show(companiesView);
      this.ui.tabs.addClass('helper-modal__tabs--second-visible');
    },
    onClickedCloseButton(event) {
      event.preventDefault();
      this.close();
    },
    initialize(options) {
      this.options = options;
    },
    placeModal() {
      const modalWidth = 360;
      this.ui.container.css({
        width: modalWidth,
        maxHeight: '100vh',
        opacity: 1,
      });

      this.ui.body.css('height', `calc(100vh - ${this._firstLevelMenuWrapHeight + this.ui.header.outerHeight()}px)`);
    },
    onRender() {
      this._firstLevelMenuWrapHeight = $('.appHeader').outerHeight();
      this.$el.css({
        height: `calc(100vh - ${this._firstLevelMenuWrapHeight}px)`,
        'margin-top': `${this._firstLevelMenuWrapHeight}px`,
      });

      this.showRegions();
    },
    showRegions() {
      const howToToursView = new HowToToursView({ collection: this.model.get('how_to_tours') });
      this.tours.show(howToToursView);
    },
    /**
     * Closes the modal.
     */
    close() {
      this.trigger('modal:close');
      this.destroy();
    },
  });

  module.exports = this.HelperModalView;
});
