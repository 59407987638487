const HelperModalView = require('./HelperModalView');
const HelperModalModel = require('../models/HelperModal');
const BookmarkModel = require('../models/Bookmark');
const BookmarkView = require('./BookmarkView');
const templateHtml = require('../templates/SectionHeaderTemplate.html');

Wethod.module('HeaderApp.Header', function (Header, Wethod, Backbone, Marionette, $, _) {
  this.SectionHeaderView = Marionette.LayoutView.extend({
    template: _.template(templateHtml),
    className: 'section-header',
    _helperModalModel: null,
    _bookmarkModel: null,
    ui: {
      helperButtonAltText: '.section-header__helper-alt',
      helperButton: '.helper-button__content',
      helperWrapper: '.section-header__helper-modal',
      helperButtonGrid: '.section-header__helper-button-grid',
    },
    regions: {
      helperModal: '[data-region="helperModalRegion"]',
      bookmark: '[data-region="bookmark"]',
    },
    events: {
      'click @ui.helperButton': 'onHelperButtonClick',
      'mouseover @ui.helperButton': 'onHelperButtonHover',
      'mouseout @ui.helperButton': 'onHelperButtonHover',
    },
    /**
     * HelperModal created when SectionHeader is initialized so it can listen to tour:start events
     * even if helperButton is never clicked.
     */
    initialize() {
      const bookmarkFragment = this.model.get('bookmark_fragment');
      if (bookmarkFragment) {
        this._bookmarkModel = new BookmarkModel({ fragment: this.model.get('bookmark_fragment') });
      }

      this._helperModalModel = new HelperModalModel(
        {
          helper_url: this.model.get('helper_url'),
          tour_id: this.model.get('tour_id'),
          tour_start_page: this.model.get('tour_start_page'),
        },
      );

      this.setDocumentTitle();
    },
    /**
     * Set the HTML document title, for example "Dashboard | hic | WETHOD".
     */
    setDocumentTitle() {
      if (Wethod.userInfo) {
        document.title = `${this.model.get('current_section')} | ${Wethod.userInfo.get('company')} | WETHOD`;
      } else {
        document.title = 'WETHOD';
      }
    },
    showBookmark() {
      if (this._bookmarkModel) {
        $.when(this._bookmarkModel.getFavorite())
          .done((favorite) => {
            this._bookmarkModel.set('favorite', favorite);
            const bookmarkView = new BookmarkView({ model: this._bookmarkModel });
            this.bookmark.show(bookmarkView);
          })
          .fail(() => {
            this._bookmarkModel.set('favorite', null);
            const bookmarkView = new BookmarkView({ model: this._bookmarkModel });
            this.bookmark.show(bookmarkView);
          });
      }
    },
    onAttach() {
      dispatcher.trigger('section-header:mounted', this.model.get('current_section'));

      this.showBookmark();
    },
    /**
     * Responds to the 'click' event on the helperButton.
     */
    onHelperButtonClick() {
      this.addShade();
      const helperModalView = new HelperModalView({
        model: this._helperModalModel,
        buttonEl: this.ui.helperButtonGrid,
      });
      this.listenTo(helperModalView, 'modal:close', this.removeShade);
      this.getRegion('helperModal').show(helperModalView);
      this.helperModal.currentView.placeModal();
    },
    toggleHelperButtonAltText() {
      this.ui.helperButtonAltText.toggleClass('move-in');
    },
    /**
     * Adds a background shade to emphasize the open modal.
     */
    addShade() {
      this.ui.helperWrapper.addClass('wethod-modal2');
    },
    /**
     * Removes the background shade used to emphasize the open modal.
     */
    removeShade() {
      this.ui.helperWrapper.removeClass('wethod-modal2');
    },
    /**
     * Responds to the 'hover' event on the helperButton.
     */
    onHelperButtonHover() {
      this.toggleHelperButtonAltText();
    },
  });

  module.exports = this.SectionHeaderView;
});
