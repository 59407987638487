const request = require('./Request');

const FeatureFlag = {
  getEnabled() {
    return request({
      method: 'get',
      url: '/feature-flags',
    });
  },
};

module.exports = FeatureFlag;
