const templateHtml = require('../templates/BookmarkTemplate.html');

Wethod.module('Header.Section', function (HeaderSection, Wethod, Backbone, Marionette, $, _) {
  this.BookmarkView = Marionette.ItemView.extend({
    className: 'section-bookmark',
    tagName: 'span',
    template: _.template(templateHtml),
    ui: {
      input: '[data-action="input"]',
    },
    events: {
      'change @ui.input': 'onInputChange',
    },
    modelEvents: {
      'change:favorite': 'onFavoriteChanged',
    },
    onFavoriteChanged() {
      this.render();
    },
    onInputChange() {
      const favorite = this.model.get('favorite');
      if (!favorite) {
        $.when(this.model.saveFavorite())
          .done((nextFavorite) => this.model.set('favorite', nextFavorite));
      } else {
        $.when(this.model.removeFavorite())
          .done(() => this.model.set('favorite', null));
      }
    },
  });

  module.exports = this.BookmarkView;
});
