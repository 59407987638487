Wethod.module('HeaderApp.Header', function (Header, Wethod, Backbone) {
  /**
   * Expects an object with:
   * - parent_section: parent section's name;
   * - current_section: current section's name;
   * - helper_preview_url;
   */
  this.SectionHeaderModel = Backbone.Model.extend({
    defaults() {
      return {
        parent_section: '',
      };
    },
  });

  module.exports = this.SectionHeaderModel;
});
