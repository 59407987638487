module.exports = class StripeService {
  constructor() {
    this.key = process.env.STRIPE_KEY;

    this.init();
  }

  init() {
    if (this.key) {
      Stripe.setPublishableKey(this.key);
    }
  }
};
