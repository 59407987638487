const ChildView = require('./HowToTourView');

Wethod.module('Header.Section', function (HeaderSection, Wethod, Backbone, Marionette) {
  this.HowToToursView = Marionette.CollectionView.extend({
    className: 'helper-modal__list',
    tagName: 'ul',
    childView: ChildView,
    childEvents: {
      'helper:tour:owner-only:clicked': 'onOwnerTourClicked',
    },
    onOwnerTourClicked(childView) {
      this.triggerMethod('helper:tour:owner-only:clicked', childView.model);
    },
  });

  module.exports = this.HowToToursView;
});
